<template>
  <div id="mian">
    <div class="header">
      <span
        @click="navClick('批量查询')"
        :class="crumbs == '批量查询' ? 'active' : 'left'"
        >批量查询</span
      >
      <span
        @click="navClick('订单查询')"
        :class="crumbs == '订单查询' ? 'active' : 'left'"
        >订单查询</span
      >
    </div>
    <!-- 批量订单查询 -->
    <div v-if="crumbs == '批量查询'">
      <!-- 上面表单框 -->
      <div class="con_from">
        <div class="lineP">
          <div class="item">
            <i>商户名称</i>
            <el-select
              v-model="query.keyword"
              filterable
              clearable
              :filter-method="searchMchList"
              @visible-change="unfoldMchList"
              @clear="clearMchList"
              @change="changeMch"
              placeholder="商户名称/店铺名称"
              :no-data-text="MerchantShopData=='' ? '无数据':'可搜索'"
            >
              <el-option
                v-for="item in MerchantShopData"
                :key="item.mchId"
                :label="item.mchName + ' | ' + item.mchId"
                :value="item.mchId"
              >
              </el-option>
            </el-select>
          </div>
          <div class="item">
            <i>产品类型</i>
            <el-select
              v-model="query.productId"
              placeholder="全部"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="(v, i) in productDrop"
                :key="i"
                :label="v.productName"
                :value="v.productId"
              ></el-option>
            </el-select>
          </div>
          <div class="item">
            <i>审核状态</i>
            <el-select
              v-model="query.checkStatus"
              placeholder="全部"
            >
              <el-option label="全部" value=""></el-option>
              <el-option label="待审核" value="WAIT"></el-option>
              <el-option label="已审核" value="PASS"></el-option>
              <el-option label="已驳回" value="REJECT"></el-option>
            </el-select>
          </div>
        </div>
        <div class="lineP">
          <div class="item">
            <i>产品通道</i>
            <el-select
              v-model="query.payChannelId"
              placeholder="全部"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="(v, i) in channelsDrop"
                :key="i"
                :label="v.payChannelName"
                :value="v.payChannelId"
              ></el-option>
            </el-select>
          </div>
          <div class="item">
            <i>交易退款金额</i>
            <el-input
              type="number"
              min="0"
              onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
              @input="inputnumStart"
              v-model="query.floorAmount"
              class="inputW"
            ></el-input>
            <span style="padding: 0px 10px;">-</span>
            <el-input
              type="number"
              min="0"
              onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
              @input="inputnumEnd"
              v-model="query.topAmount"
              class="inputW"
            ></el-input>
          </div>
        </div>
        <div class="lineP">
          <div class="item itemTime">
            <i>时间类型</i>
            <el-select
              v-model="query.timeType"
              style="width: 130px;"
            >
              <el-option label="申请时间" value="APPLY"></el-option>
              <el-option label="审核时间" value="REFUND"></el-option>
            </el-select>
            <span style="padding: 0 10px"></span>
            <el-date-picker
              v-model="query.startTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择时间"
            >
            </el-date-picker>
            <span style="padding-left: 10px; padding-right: 10px">-</span>
            <el-date-picker
              v-model="query.endTime"
              type="datetime"
              placeholder="选择时间"
            >
            </el-date-picker>
          </div>
          <div class="itembtn">
            <el-button class="searchBt" @click="getLists">查询</el-button>
            <el-button
              v-auth="'TRADE:RECEIPT:REFUND_CHECK/REJECT'"  :disabled="disabledFlag"
              class="searchBt"
              @click="setStatusS(false)"
              >批量驳回</el-button
            >
            <el-button
              v-auth="'TRADE:RECEIPT:REFUND_CHECK/PASS'"  :disabled="disabledFlag"
              class="searchBt" 
              @click="setStatusS(true)"
              >批量通过</el-button
            >
          </div>
        </div>
      </div>
      <!-- 中间交易账目 -->
      <div class="jiaoyi">
        <div class="jiaoyi_box" style="margin-right: 16px">
          <p><i>退款总数（笔）</i></p>
          <p>
            <b>{{ refundNum }}</b>
          </p>
        </div>
        <div class="jiaoyi_box">
          <p><i>交易退款金额（元）</i></p>
          <p>
            <b>{{ (refundAmount / 100) | formatMoney }}</b>
          </p>
        </div>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td style="width: 30px">
              <p>
                <el-checkbox
                  v-model="allSelect"
                  @change="parChange"
                ></el-checkbox>
              </p>
            </td>
            <td>
              <p>申请时间</p>
              <p>审核时间</p>
            </td>
            <td>
              <p>商户名称</p>
              <p>店铺名称</p>
            </td>
            <td>
              <p>商户订单号</p>
              <p>交易流水号</p>
            </td>
            <td>
              <p>退款流水号</p>
            </td>
            <td>
              <p>产品类型</p>
              <!-- <p>产品通道</p> -->
            </td>
            <td>
              <p>交易金额(元)</p>
            </td>
            <td>
              <p>交易退款金额(元)</p>
              <p>营销退款金额(元)</p>
            </td>
            <td style="width: 70px">
              <p>审核状态</p>
            </td>
            <td style="width: 80px">
              <p>操作</p>
            </td>
          </tr>
          <tr v-for="(v, i) in tabData" :key="i">
            <td style="width: 30px">
              <p>
                <el-checkbox
                  v-model="v.active"
                  @change="chidChange"
                ></el-checkbox>
              </p>
            </td>
            <td>
              <p>{{ v.createTime }}</p>
              <p>{{ v.refundTime }}</p>
            </td>
            <td>
              <p>{{ v.mchName }}</p>
              <p>{{ v.storeName }}</p>
            </td>
            <td>
              <p>{{ v.mchOrderNo }}</p>
              <p>{{ v.plfOrderNo }}</p>
            </td>
            <td>
              <p>{{ v.plfRefundNo }}</p>
            </td>
            <td>
              <p>{{ v.productName }}</p>
            </td>
            <td>
              <p>{{ (v.orderAmount / 100) | formatMoney }}</p>
            </td>
            <td>
              <p>{{ (v.refundAmount / 100) | formatMoney }}</p>
              <p>{{ (v.marketingRefundAmount / 100) | formatMoney }}</p>

            </td>
            <td style="width: 70px">
              <p>{{ checkStatus[v.checkStatus] }}</p>
            </td>
            <td style="width: 80px">
              <p>
                <i v-auth="'TRADE:RECEIPT:REFUND_CHECK/DETAIL'"
                  class="lianjie"
                  @click="showTab(v)"
                  >详情</i
                >
              </p>
              <p v-if="v.checkStatus == 'WAIT'">
                <i  v-auth="'TRADE:RECEIPT:REFUND_CHECK/PASS'"  class="lianjie"  :disabled="disabledFlag"  @click="setStatus(v, true)" >
                  通过
                </i> 
                <i  v-auth="'TRADE:RECEIPT:REFUND_CHECK/REJECT'" :disabled="disabledFlag"
                  @click="setStatus(v, false)"
                  class="lianjie"
                  >驳回</i>
              </p>
            </td>
          </tr>
        </table>
      </div>
      <el-pagination
        ref="pagination"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalNum"
      >
      </el-pagination>
    </div>
    <!-- 按订单号查询 -->
    <div v-if="crumbs == '订单查询'">
      <div class="con_from">
        <div class="lineP">
          <div class="item itemTwo">
            <!-- <i>退款订单号</i> -->
            <el-select
              v-model="formData.orderNoType"
              placeholder=""
              style="width: 170px; margin-right: 20px"
            >
              <!-- <el-option value="mchRefundNo" label="退款订单号"></el-option> -->
              <el-option value="mchOrderNo" label="商户订单号"></el-option>
              <el-option value="plfOrderNo" label="交易流水号"></el-option>
              <el-option value="upsOrderNo" label="三方订单号"></el-option>
              <el-option value="thdOrderNo" label="三方流水号"></el-option>
              <el-option value="plfRefundNo" label="退款流水号"></el-option>
            </el-select>
            <el-input
              v-model="formData.orderNo"
              maxlength="128"
              style="width: calc(100% - 170px - 20px);"
              suffix-icon="el-icon-search"
              :placeholder="'搜索' + orderNoType[formData.orderNoType]"
            ></el-input>
          </div>
          <div class="itembtn itemTwobtn">
            <el-button class="showBt" @click="getDetail">查询</el-button>
          </div>
        </div>
      </div>
      <div class="tab1" style="min-height: 700px">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>申请时间</p>
              <p>审核时间</p>
            </td>
            <td>
              <p>商户名称</p>
              <p>店铺名称</p>
            </td>
            <td>
              <p>商户订单号</p>
              <p>交易流水号</p>
            </td>
            <td>
              <p>退款流水号</p>
            </td>
            <td>
              <p>产品类型</p>
            </td>
            <td>
              <p>交易金额 (元)</p>
            </td>
            <td>
              <p>交易退款金额(元)</p>
              <p>营销退款金额(元)</p>
            </td>
            <td>
              <p>审核状态</p>
            </td>
            <td style="width: 80px">
              <p>操作</p>
            </td>
          </tr>
          <tr v-for="(v, i) in tabData" :key="i">
            <td>
              <p>{{ v.createTime }}</p>
              <p>{{ v.refundTime }}</p>
            </td>
            <td>
              <p>{{ v.mchName }}</p>
              <p>{{ v.storeName }}</p>
            </td>
            <td>
              <p>{{ v.mchOrderNo }}</p>
              <p>{{ v.plfOrderNo }}</p>
            </td>
            <td>
              <p>{{ v.mchRefundNo }}</p>
              <p>{{ v.plfRefundNo }}</p>
            </td>
            <td>
              <p>{{ v.productName }}</p>
            </td>
            <td>
              <p>{{ (v.orderAmount / 100) | formatMoney }}</p>
            </td>
            <td>
              <p>{{ (v.refundAmount / 100) | formatMoney }}</p>
              <p>{{ (v.marketingRefundAmount / 100) | formatMoney }}</p>
            </td>
            <td>
              <p>{{ checkStatus[v.checkStatus] }}</p>
            </td>
            <td style="width: 80px">
              <p><i class="lianjie" @click="showTab(v)" v-auth="'TRADE:RECEIPT:REFUND_CHECK/DETAIL'">详情</i></p>
              <p v-if="v.checkStatus == 'WAIT'">
                <i
                  v-auth="'TRADE:RECEIPT:REFUND_CHECK/PASS'" :disabled="disabledFlag"
                  @click="setStatus(v, true)"
                  class="lianjie"
                  >通过</i
                >
                <i
                  v-auth="'TRADE:RECEIPT:REFUND_CHECK/REJECT'" :disabled="disabledFlag"
                  @click="setStatus(v, false)"
                  class="lianjie"
                  >驳回</i
                >
              </p>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { formatDate } from "@/utils/common.js";
import {
  getRefundchecks,
  getRefundchecksSearch,
  setCheck,
} from "@/api/trading/gathering.js";
import { payproductsDrop, paychannelsDrop ,MerchantShopDrop} from "@/api/common.js";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      query: {
        startTime: formatDate(+new Date(), "yyyy-MM-dd 00:00:00"),
        endTime: formatDate(+new Date(), "yyyy-MM-dd 23:59:59"),
        timeType: "APPLY",
        pageNo: 1,
        pageSize: 10,
        floorAmount: "",
        topAmount: "",
        productId: "",
        payChannelId: "",
        checkStatus: "",
        keyword: "",
      },
      totalNum: 0,
      refundNum: 0,
      refundAmount: 0,
      formData: {
        orderNoType: "mchOrderNo",
        orderNo: "",
      },
      orderNoType: {
        mchRefundNo: "退款订单号",
        plfRefundNo: "退款流水号",
        mchOrderNo: "商户订单号",
        plfOrderNo: "交易流水号",
        upsOrderNo: "三方订单号",
        thdOrderNo: "三方流水号",
      },
      orderNo: "", //当订单查询输入框的订单号被清空时,缓存被点击的审核或者驳回的商户订单号

      productDrop: [],
      channelsDrop: [],
      tabData: [],
      refundStatus: {
        WAIT: "已审核",
        REJECT: "已驳回",
        SUCCESS: "已审核",
        FAIL: "已审核",
        PROCESSING: "退款中",
      },
      checkStatus: {
        WAIT: "待审核",
        REJECT: "已驳回",
        PASS: "已审核",
      },
      crumbs: "批量查询",
      allSelect: false,
      disabledFlag: false,//防止重复点击
      MerchantShopData: [], //分账商户名称/编号可选项
      keywordData:{
        mchId:"",
        mchName:"",
      },
    };
  },
  computed: {
    ...mapState({
      refundShDetail: "trading_refund_sh",
    }),
  },
  created() {
    if (this.refundShDetail) {
      this.query = this.refundShDetail.query;
      this.formData = this.refundShDetail.formData;
      this.keywordData = this.refundShDetail.keywordData;
    }
    if(JSON.stringify(this.keywordData) != '{}' && this.keywordData != undefined && this.keywordData.mchId != ""){
      this.query.keyword = this.keywordData.mchName + ' | ' + this.keywordData.mchId;
    }
    this.$nextTick(() => {
      this.$refs.pagination.internalCurrentPage = this.query.pageNo;
    });
    this.getDrop();
    this.getLists(false);
    this.$enter(this.$route.path, this.search);
  },
  methods: {
    ...mapMutations({
      setRefundSh: "trading_setRefund_sh",
    }),
    search() {
      if (this.crumbs == "批量查询") {
        this.getLists();
      } else {
        this.getDetail();
      }
    },

    //搜索-交易金额
    inputnumStart(e){
      if(e == -1){
        this.query.floorAmount = "";
      }else{
        if(!(/^(\d?)+(\.\d{0,2})?$/.test(e))){  
          e = e.substring(0, e.length - 1); 
          this.$message.error("请输入小数点后两位");
        }
        this.query.floorAmount = e;
      }
    },
    inputnumEnd(e){
      if(e == -1){
        this.query.topAmount = "";
      }else{
        if(!(/^(\d?)+(\.\d{0,2})?$/.test(e))){  
          e = e.substring(0, e.length - 1); 
          this.$message.error("请输入小数点后两位");
        }
        this.query.topAmount = e;
      }
    },

    // 获取详情
    getLists(reset = true) {
      if (reset) {
        this.query.pageNo = 1;
      }
      let query = { ...this.query };
      query.floorAmount = query.floorAmount
        ? this.accMul(query.floorAmount, 100)
        : "";
      query.topAmount = query.topAmount
        ? this.accMul(query.topAmount, 100)
        : "";
      // query.keyword = query.keyword.trim();
      if(this.keywordData != undefined && this.keywordData.mchId != ""){
        query.keyword = this.keywordData.mchId;
      }
      getRefundchecks(query).then((res) => {
        if (res) {
          if (this.crumbs == "批量查询") {
            this.allSelect = false;
            this.tabData = res.resultData.refunds;
            this.totalNum = res.resultData.totalNum;
            this.refundNum = res.resultData.refundNum;
            this.refundAmount = res.resultData.refundAmount;
          }
        }
      });
    },

    //  处理计算失真
    accMul(arg1, arg2) {
      var m = 0,
        s1 = arg1.toString(),
        s2 = arg2.toString();
      try {
        m += s1.split(".")[1].length;
      } catch (e) {}
      try {
        m += s2.split(".")[1].length;
      } catch (e) {}
      return (
        (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
        Math.pow(10, m)
      );
    },
    // 全选
    parChange() {
      if (this.allSelect) {
        this.tabData.forEach((v) => {
          v.active = true;
        });
      } else {
        this.tabData.forEach((v) => {
          v.active = false;
        });
      }
      this.tabData = JSON.parse(JSON.stringify(this.tabData));
    },
    //多选
    chidChange() {
      let length = 0;
      this.tabData.forEach((v) => {
        if (v.active) {
          length++;
        } else {
          this.allSelect = false;
        }
      });
      if (length == this.tabData.length) {
        this.allSelect = true;
      }
    },
    // 获取搜索详情
    getDetail() {
      let params = {
        orderNoType: this.formData.orderNoType,
        orderNo: this.formData.orderNo.trim(),
      };
      if (params.orderNo) {
        getRefundchecksSearch(params).then((res) => {
          if (res) {
            if (this.crumbs == "订单查询") {
              this.tabData = res.resultData;
              if (this.tabData.length == 0) {
                this.$message({
                  message: "暂无数据",
                  duration: 1500,
                });
              }
            }
          }
        });
      } else {
        this.$message.error(
          "请输入" + this.orderNoType[this.formData.orderNoType]
        );
      }
    },
    // 获取下拉
    getDrop() {
      payproductsDrop().then((res) => {
        if (res) {
          this.productDrop = res.resultData;
        }
      });
      paychannelsDrop().then((res) => {
        if (res) {
          this.channelsDrop = res.resultData;
        }
      });
    },
    // 跳转详情
    showTab(value) {
      this.$router.push({
        name: "refund_tkxq",
        query: {
          id: value.refundId,
        },
      });
    },
    // 设置通过、驳回
    setStatus(value, bool) {
      // return
      this.orderNo = value.mchOrderNo;
      let data = {
        refundIds: [value.refundId],
        refundStatus: bool ? "PASS" : "REJECT",
      };
      this.disabledFlag = true
      setCheck(data).then((res) => {
        this.disabledFlag = false;
        if (this.crumbs == "批量查询") {
          this.getLists(false);
        } else {
          let params = {
            orderNoType: "mchOrderNo",
            orderNo: this.orderNo,
          };
          getRefundchecksSearch(params).then((res1) => {
            console.log("请求列表11", res1);
            if (res1.resultStatus) {
              if (this.crumbs == "订单查询") {
                this.tabData = res1.resultData;
              }
            }
          });
        }
        this.allSelect = true;
        if (res.resultStatus) {
          this.$message.success(bool ? "审核通过" : "审核驳回");
        }
      });
    
    },
    //菜单切换
    navClick(data) {
      this.crumbs = data;
      this.tabData = [];
      if (data == "批量查询") {
        this.getLists();
      } else {
        if (this.formData.orderNo) {
          this.getDetail();
        }
      }
    },
    // 批量
    setStatusS(bool) {
      let refundIds = [];
      let tabData = [];
      this.tabData.forEach((v) => {
        if (v.active) {
          tabData.push(v);
        }
      });
      if (tabData.length <= 0) {
        this.$message.error(
          bool ? "请勾选需要审核的订单" : "请勾选需要驳回的订单"
        );
        return;
      }
      tabData.forEach((v) => {
        if (v.checkStatus == "WAIT") {
          refundIds.push(v.refundId);
        }
      });
      if (refundIds.length <= 0) {
        this.$message.error("勾选的订单已全部审核完毕");
        return;
      }
      let data = {
        refundIds: refundIds,
        refundStatus: bool ? "PASS" : "REJECT",
      };
      this.disabledFlag = true
      setCheck(data).then((res) => {
        if (res) {
          this.$message.success(bool ? "审核通过" : "审核驳回");
          this.getLists(false);
          this.disabledFlag = false;
          this.tabData = '';
          this.allSelect = false;
        }
      });
    },
    // 获取商户下拉列表
    _searchAllMch(value) {
      this.MerchantShopData = [];
      let params = {
        keyword: value,
      };
      MerchantShopDrop(params).then((res) => {
        if (res.resultStatus) {
          this.MerchantShopData = res.resultData;
          console.log(this.MerchantShopData);
        }
      });
    },
    // 商户下拉列表输入搜索内容时
    searchMchList(val) {
      if (val) {
        let value = val;
        this._searchAllMch(value);
      } else {
        // 搜索内容为空时还原列表
        this._searchAllMch();
      }
    },
    // 商户下拉框展开/收起时触发
    unfoldMchList(e) {
      if (e) {
        //  展开时
        this._searchAllMch();
      } else {
        //  折叠时
        this.MerchantShopData = [];
      }
    },
    // 商户下拉搜索框清空搜索内容时
    clearMchList() {
      this.query.keyword = ""; //清空选中的商户id
      this.keywordData = {};
      this._searchAllMch(); //重新获取所有商户下拉列表
    },
    changeMch(e) {
      this.query.keyword = e;
      let mchId  = this.query.keyword;
      console.log( this.query.keyword );
      let result = this.MerchantShopData.filter(e=>e.mchId === mchId)
      if(result && result.length > 0){
        console.log(result[0])
        this.keywordData = result[0];
      }
    }, 
    // 页面大小改变
    handleSizeChange(size) {
      this.query.pageSize = size;
      this.getLists();
    },
    handleCurrentChange(page) {
      this.query.pageNo = page;
      this.getLists(false);
    },
  },
  beforeDestroy() {
    let data = {
      query: this.query,
      formData: this.formData,
      keywordData: this.keywordData,
    };
    this.setRefundSh(data);
  },
};
</script>
<style scoped>
.con_from .lineP .item{
  width: 29%;
  margin-left: 6.5%;
}
.con_from .lineP .item:first-child{
  margin-left: 0%;
}
.con_from .lineP .item i {
  width: 92px;
}
.con_from .lineP .itemTime{
  width: 64.51%;
}
.con_from .lineP .item .el-input , .con_from .lineP .item .el-select{
  width: calc(100% - 92px - 10px);
}
.con_from .lineP .itemTime .el-input{
  width: calc((100% - 130px - 92px - 44.67px - 10px) / 2);
}
.con_from .lineP .item .inputW {
  width: calc((100% - 92px - 24.67px - 10px) / 2);
}
.con_from .lineP .item .inputW ::v-deep .el-input__inner{
  padding: 0px 0px 0px 15px !important;
}
.con_from .lineP .itemTwo {
    width: 38% !important;
}
.jiaoyi_box {
  width: 49%;
}


.showBt {
  float: right;
  background: #48b8b6;
  border-color: #48b8b6;
  margin-left: 20px;
  width: 90px;
  height: 36px;
  padding: 0;
  color: #ffffff;
}

.jianju2 {
  display: inline-block;
  width: 51px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  vertical-align: middle;
}

.inputW {
  width: 79px;
  height: 36px;
}

.searchBt {
  float: right;
  background: #48b8b6;
  border-color: #48b8b6;
  margin-left: 20px;
  width: 90px;
  height: 36px;
  padding: 0;
  color: #ffffff;
}

.addDetailBt:hover {
  background-color: #fff;
  color: #48b8b6;
}

.addDetailBt {
  float: right;
  width: 90px;
  height: 36px;
  margin-left: 20px;
  border-color: #d7dae2;
  padding: 0;
}
</style>
